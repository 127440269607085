import React from 'react'
import Practical from '../../components/Practical'
import samuel from "../../assets/samuel.jpg"
import ffg from "../../assets/ffg.jpg"
import deji from "../../assets/ay_deji.jpg";
import rahmat from "../../assets/rahimat.jpg"
import moses from "../../assets/moses.jpg"
import emma from "../../assets/emma.jpg"
import eniola from "../../assets/eniola.jpg"

function Review() {
    return (
        <>   
		<section class="head">
			<div class="bg-video">
				<video autoplay muted loop class="bg-video__content">
					<source src="../video/ds_intern.mp4" type="video/mp4" />
					<source src="../video/ds_intern.webm" type="video/webm" />	
					Your browser does not support the background video!
				</video>
			</div>

			<div class="head__text-box">
			<h1 class="heading-pry">
				<span class="heading-pry--main">unilag design studio</span>
				<span class="heading-pry--sub">A place for innovation</span>
			</h1>
	
			<a href="#section-tours" class="btn btn--white btn--animated">Chech out our designs</a>
			</div>
		</section>

        <section class="section-stories" id="section-stories">
			<div class="bg-video">
				<video autoplay muted loop class="bg-video__content">
					<source src="../video/video.mp4" type="video/mp4" />
					<source src="../video/video.webm" type="video/webm" />	
					Your browser does not support the background video!
				</video>
			</div>

			<div class="u-center-text u-margin-bottom-big">
				<h2 class="heading-sec">
					Our interns always stand out worldwide
				</h2>
			</div>

			<div class="container">
				<div class="row u-margin-bottom-medium">
					<div class="story">
						<figure class="story__shape">
							<img src={samuel} alt="person on a tour" class="story__image" />
							<figcaption class="story__caption">Samuel Akinremi</figcaption>
						</figure>

						<div class="story__text">
							<h3 class="heading-ter u-margin-bottom-small">My exposure has a wider horizon</h3>
							<p>
								This internship has exposed me to the endless opportunities that are available in the engineering field and how I can impact the health situation of Nigeria for good through innovation
							</p>
						</div>
					</div>
				</div>

				<div class="row u-margin-bottom-medium">
					<div class="story">
						<figure class="story__shape">
							<img src={ffg} alt="person on a tour" class="story__image" />
							<figcaption class="story__caption">Faruq Fakoyede</figcaption>
						</figure>

						<div class="story__text">
							<h3 class="heading-ter u-margin-bottom-small">I am now more technical</h3>
							<p>
								This internship has made me master professional soft skills such as communication, punctuality and time management.
							</p>
						</div>
					</div>
				</div>

				<div class="row u-margin-bottom-medium">
					<div class="story">
						<figure class="story__shape">
							<img src={deji} alt="person on a tour" class="story__image" />
							<figcaption class="story__caption">Ayodeji Oyelakin</figcaption>
						</figure>

						<div class="story__text">
							<h3 class="heading-ter u-margin-bottom-small">I love that I'm working on project that can help people's lives</h3>
							<p>
								The rice internship was a really exciting one. the team based projects helped me learn how to work with other people. I'm really grateful to have been part of the first cohort.
							</p>
						</div>
					</div>
				</div>

				<div class="row u-margin-bottom-medium">
					<div class="story">
						<figure class="story__shape">
							<img src={rahmat} alt="person on a tour" class="story__image" />
							<figcaption class="story__caption">Rahamat Muftaudeen</figcaption>
						</figure>

						<div class="story__text">
							<h3 class="heading-ter u-margin-bottom-small">I am now a healthcare solution provider</h3>
							<p>
								The internship taught me how to apply core engineering principles for the understanding and advancement of medical and healthcare technologies. I have also learnt to provide solution to some specific problems in medicine.
							</p>
						</div>
					</div>
				</div>


				<div class="row u-margin-bottom-medium">
					<div class="story">
						<figure class="story__shape">
							<img src={moses} alt="person on a tour" class="story__image" />
							<figcaption class="story__caption">Moses Onowenoni</figcaption>
						</figure>

						<div class="story__text">
							<h3 class="heading-ter u-margin-bottom-small">My mind is now open to loads of opportunities</h3>
							<p>
								The Rice 360 internship has opened my mind to a world of opportunities and has shown me ways to efficiently apply my knowledge in medical science in solving problems.
							</p>
						</div>
					</div>
				</div>

				<div class="row u-margin-bottom-medium">
					<div class="story">
						<figure class="story__shape">
							<img src={emma}alt="person on a tour" class="story__image" />
							<figcaption class="story__caption">Emmanuel Aworesan</figcaption>
						</figure>

						<div class="story__text">
							<h3 class="heading-ter u-margin-bottom-small">I am now motivated</h3>
							<p>
								Intening at UNILAG Design Studio has really motivated me to passionately grow the healthcare of Nigeria using cost effective locally made materials to achieving effective results. Cheers
							</p>
						</div>
					</div>
				</div>

				<div class="row u-margin-bottom-medium">
					<div class="story">
						<figure class="story__shape">
							<img src={eniola} alt="person on a tour" class="story__image" />
							<figcaption class="story__caption">Eniola Alex</figcaption>
						</figure>

						<div class="story__text">
							<h3 class="heading-ter u-margin-bottom-small">Phenomenal Exposure</h3>
							<p>
								The design studio is filled with a sense of productivity.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
        <Practical />
        </>
    )
}

export default Review
