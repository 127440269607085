import React, {useState} from 'react';
import { useNavigate } from 'react-router';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import httprequest from '../../httpsrequests/httprequest';


const theme = createTheme();

function Account() {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const handleSubmit = async (event) => {
    try{
        event.preventDefault();
        const formdata = new FormData(event.currentTarget);
        const data = {email: formdata.get('email'),password: formdata.get('password'),oldPassword: formdata.get('oldpassword')}
        console.log(data)
        const response = await httprequest.patch("/auth/password-reset",data)
        console.log(response);
        // //push to admin page
        setSuccess(true)
        window.setTimeout(()=>{
          navigate("/admin")
        },2000)
    }catch(err){
        setError(true)
        console.log(err.response)
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            change password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="oldpassword"
              label="Old Password"
              type="password"
              id="oldpassword"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="oldpassword"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Change password
            </Button>
          </Box>
          {
            (success && !error) && <div className="success">Password Changed successfully</div>
          }
          {
            (!success && error) && <div className="error">Unable to change password</div>
          }
        </Box>
      </Container>
    </ThemeProvider>
  );
}
export default Account
