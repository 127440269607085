import React from 'react'
import {BrowserRouter, Routes , Route} from "react-router-dom"
import ScrollTop from './components/ScrollTop'
import AdminContainer from './screens/admin/AdminContainer'
import Dashboard from './screens/admin/Dashboard'
import EventsAdd from './screens/admin/EventsAdd'
import Login from './screens/admin/Login'
import EventAdmin from './screens/admin/Event'
import Event from './screens/users/Event'
import Home from './screens/users/Home'
import HomeContainer from './screens/users/HomeContainer'
import Review from './screens/users/Review'
import EventEdit from './screens/admin/EventEdit'
import Account from './screens/admin/Account'

function App() {
  return (
    <BrowserRouter>
      <ScrollTop>
        <Routes>
          <Route path="/" element={<HomeContainer />}>
            <Route path="/" element={<Home />} />
            <Route path="/event/:id" element={<Event />} />
            <Route path="/intern-review" element={<Review />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<AdminContainer />}>
            <Route path="/admin/" element={<Dashboard />} />
            <Route path="/admin/events/:id" element={<EventAdmin />} />
            <Route path="/admin/events/add" element={<EventsAdd />} />
            <Route path="/admin/events/edit/:id" element={<EventEdit />} />
            <Route path="/admin/account" element={<Account />} />
          </Route>
        </Routes>
      </ScrollTop>
    </BrowserRouter>
  )
}

export default App
