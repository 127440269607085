import React from 'react';
import {Link} from "react-router-dom"

function Sidebar() {
    return (
        <ul className="admin-sidebar">
            <li className="sidebar__item">
                <Link className="sidebar__link" to="/admin">Events</Link>
            </li>
            <li className="sidebar__item">
                <Link className="sidebar__link" to="/admin/events/add">Add Events</Link>
            </li>
            <li className="sidebar__item">
                <Link className="sidebar__link" to="/admin/account">Password Change</Link>
            </li>
        </ul>
    )
}

export default Sidebar
