import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import AddIcon from '@mui/icons-material/Add';
import Sidebar from '../../components/Sidebar'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
// import EventsAddForm from './EventsAddForm';
import httprequest from '../../httpsrequests/httprequest';

function EventsAdd() {
    const navigate = useNavigate()
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("")
    const [requirements, setRequirements] = useState([""]);
    const [benefits, setBenefits] = useState([""]);
    const [deadline, setDeadline] = useState("")
    const [formlink, setGoogleLink] = useState("")
    const [themeColor, setThemeColor] = useState("#000000")
    const [photo, setPhoto] = useState("")
    const [imagePrev, setImagePrev] = useState("")
    const [loading, setLoading] = useState(false);

    const handleDescription = (e)=>{
        setDescription(e)
    }
    const addRequirements = ()=>{
        setRequirements([...requirements,""])
    }
    const addBenefits = () =>{
        setBenefits([...benefits, ""])
    }
    const handleRequirement = (e,index)=>{
        const {value} = e.target;
        setRequirements(requirements.map((req,ind)=>{
            if(index === ind ){
                return value
            }
            return req
        }))
    }
    const handleBenefits = (e,index)=>{
        const {value} = e.target;
        setBenefits(benefits.map((ben,ind)=>{
            if(index === ind ){
                return value
            }
            return ben
        }))
    }
    const handleChangeThemeColor = (e)=>{
        const {value} = e.target
        console.log(value)
        setThemeColor(value)
    }
    const handleSetImage = (e) =>{
        const {files} = e.target;
        setPhoto(files[0]);
        setImagePrev(window.URL.createObjectURL(files[0]))
    }

    const handleSubmit = async(e)=>{
        e.preventDefault();
        const formdata = new FormData();
        formdata.append("title",title)
        formdata.append("description",description)
        formdata.append("benefits",benefits)
        formdata.append("deadline",deadline)
        formdata.append("requirements",requirements)
        formdata.append("formlink",formlink)
        formdata.append("themeColor",themeColor)
        formdata.append("photo",photo)
        setLoading(true)
        try{
            const response = await httprequest.post("/events",formdata)
            //redirect to page with new event
            console.log(response);
            navigate("/admin")
        }catch(err){
            console.log(err.response)
        }finally{
            setLoading(false)
        }
    }
    return (
      
            <div className="admin__main">
                <h1 className="main__heading">Create A new Event</h1>
                <form className="event__form">
                    <div className="event__form-group">
                        <label className="event__form-label">Event Image</label>
                        <input type="file" className="event__form-input" accept="image/*" onChange={handleSetImage} />
                        <div>
                            {imagePrev && <img src={imagePrev} alt="preview" className="event__form--preview"/>}
                        </div>
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label">Event Title / Name </label>
                        <input type="text" value={title} onChange={e=>setTitle(e.target.value)} className="event__form-input" />
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label">description </label>
                        <ReactQuill style={{height: "200px", marginBottom:"50px"}} value={description} onChange={handleDescription} />
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label" >Theme Color</label>
                        <div className="eventcolor">
                            <input type="color" value={themeColor} onChange={handleChangeThemeColor} />
                            <div className="eventcolor__display" style={{backgroundColor:themeColor}}></div>
                        </div>
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label">Requirements for participation</label>
                        {requirements.map((req,index)=><input type="text" onChange={(e)=>handleRequirement(e,index)} className="event__form-input" value={req} />)}
                        <button onClick={addRequirements} type="button" className="event__form-icon"><AddIcon /></button>
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label">Benefits for participation</label>
                        {benefits.map((ben,index)=><input type="text" onChange={(e)=>handleBenefits(e,index)} className="event__form-input" value={ben} />)}
                        <button onClick={addBenefits} type="button" className="event__form-icon"><AddIcon /></button>
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label">set deadline for submission</label>
                        <input type="date" onChange={e=>setDeadline(e.target.value)} min={new Date().toISOString().split("T")[0]} />
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label">Google Form Link</label>
                        <input type="text" value={formlink} onChange={e=>setGoogleLink(e.target.value)} className="event__form-input" />
                    </div>
                    <button onClick={handleSubmit} href="#section-booking" class="btn btn--green" disabled={loading}>
                           {loading?"loading...": "Submit"}
                    </button>
                    {/* <EventsAddForm /> */}
                </form>
            </div>
       
    )
}

export default EventsAdd