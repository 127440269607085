import { Skeleton } from '@mui/material';
import React, {useEffect, useState} from 'react'
import {useParams, useNavigate, Link} from "react-router-dom"
import httprequest from '../../httpsrequests/httprequest';
import parse from "html-react-parser"
const getDate = (date)=>{
    return new Date(date).toDateString();
}
function Event() {
    const navigate = useNavigate()
    const {id} = useParams();
    const [event, setEvent] = useState({})
    useEffect(() => {
        httprequest.get(`/events/${id}`)
        .then(res=>{
            console.log(res)
            setEvent(res.data.data)
        })
        .catch(err=>{
            console.log(err.response)
        })
    }, [])
    
    const deleteEvent = async ()=>{
        try{
            httprequest.delete(`/events/${id}`);
            window.setTimeout(()=>{
                navigate("/admin")
            },500)
        }catch(err){
            window.alert("unable to delete, please try again");
        }
    };
    if(event.title === "undefined"){
        return(<Skeleton />)
    }
    return (
        <div className="homeevent">
            <h2 className="heading-sec homeevent__heading">{event.title}</h2>
            <div className="homeevent__details">
            {event?.description && parse(event?.description)}
            </div>
            <div className="homeevent__req__flex">
                <div>
                    <h3 className="homeevent__req--h3">Requirements</h3>
                    <ul className="homeevent__req--list">
                        {event?.requirements?.map(req=><li key={req}>{req}</li>)}
                    </ul>
                </div>
                <div>
                    <h3 className="homeevent__req--h3">Benefits</h3>
                    <ul className="homeevent__req--list">
                        {event?.benefits?.map(req=><li key={req}>{req}</li>)}
                    </ul>
                </div>    
            </div>
                <div className="homeevent__register--div">
                    <p className="homeevent__deadline">Dealine: {getDate(event?.deadline)} </p>
                    <p className="homeevent__deadline">form link:
                    <a href={event?.formlink} target="_blank">{event?.formlink}</a> 
                         </p>
                </div>
                <div className="admin-event__btns">
                    <Link to={`/admin/events/edit/${id}`} className="admin-event__btn">Edit Event</Link>
                    <button onClick={deleteEvent} className="admin-event__btn admin-event__btn--delete">Delete Event</button>
                </div>    
        </div>
    )
}

export default Event
