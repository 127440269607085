import React from 'react';
import {Link} from "react-router-dom";
import logo from "../assets/uds_logo.png"

function Header() {
    return (
        <header class="header">
			<Link to="/">
				<img src={logo} alt="logo" class="logo" />
			</Link>
	
			<form action="#" class="search">
				<input type="text" class="search__input" placeholder="Search our design studio" />
	
				<button class="search__button">
					<i class="search__icon fa fa-search"></i>
				</button>
			</form>
	
			<nav class="sidebar">
				<ul class="side-nav">
					<li class="side-nav__item side-nav__item--active">
						<a href="#" class="side-nav__link">
							<ion-icon name="logo-home"></ion-icon>
							<span class="side-nav__text"> home </span>
						</a>
					</li>
	
					<li class="side-nav__item">
						<a href="#" class="side-nav__link">
							<ion-icon name="logo-home"></ion-icon>
							<span class="side-nav__text"> services </span>
						</a>
					</li>
	
					<li class="side-nav__item">
						<a href="#" class="side-nav__link">
							<ion-icon name="person-circle-outline"></ion-icon>
							<span class="side-nav__text"> intern review </span>
						</a>
					</li>
	
					<li class="side-nav__item">
						<a href="#" class="side-nav__link">
							<ion-icon name="person-add-outline"></ion-icon>
							<span class="side-nav__text"> enrolment </span>
						</a>
					</li>
				</ul>
			</nav>
		</header>
    )
}

export default Header
