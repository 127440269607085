import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import "../../styles/style.css"

// import "../../stylese_modules/sweetalert2/dist/sweetalert2.css"

import "../../styles/base/animations.css"
import "../../styles/base/base.css"
import "../../styles/base/utility.css"

import "../../styles/components/button.css"
import "../../styles/components/composition.css"
import "../../styles/components/popup.css"
import "../../styles/components/feature-box.css"
import "../../styles/components/story.css"
import "../../styles/components/card.css"
import "../../styles/components/bg-video.css"
import "../../styles/components/form.css"
import "../../styles/components/gallery.css"

import "../../styles/layouts/navigation.css"
import "../../styles/layouts/header.css"
import "../../styles/layouts/grid.css"
import "../../styles/layouts/footer.css"
import "../../styles/pages/home.css"

import "../../styles/bootstrap/css/bootstrap.css"
import "../../styles/bootstrap/css/bootstrap.min.css"
import "../../styles/bootstrap/css/bootstrap-grid.css"
import "../../styles/bootstrap/css/bootstrap-grid.min.css"
import "../../styles/bootstrap/css/bootstrap-reboot.css"
import "../../styles/bootstrap/css/bootstrap-reboot.min.css"

// images
import clothing from "../../assets/clothing.jpg";
import pic2 from "../../assets/rendering-character-design.jpg"
import pics3 from "../../assets/img-3.jpg" 
import pics4 from "../../assets/lap_design.jpg" 
import pics5 from "../../assets/jet.jpg" 
import pics6 from "../../assets/img-6.jpg" 
import pics7 from "../../assets/img-7.png" 
import pics8 from "../../assets/img-8.jpg" 
import pics9 from "../../assets/img-9.png" 
import pics10 from "../../assets/img-10.jpg" 
import pics11 from "../../assets/img-11.jpg" 
import pics12 from "../../assets/img-12.jpg" 
import pics13 from "../../assets/img-13.jpg" 
import pics14 from "../../assets/img-14.jpg" 
import drone from "../../assets/drone.jpg"
import drone2 from "../../assets/arduino in use.jpg"
import interns from "../../assets/interns.jpg"
import interns2 from "../../assets/3D_image.jpg"
import vicky from "../../assets/vicky.jpg"
import ayboss from "../../assets/ay_boss.jpg";
import Practical from '../../components/Practical';
import httprequest from '../../httpsrequests/httprequest';
import { Skeleton } from '@mui/material';


function Home() {
	const [events, setEvents]=  useState([]);
	const [loading, setLoading] = useState(true);
    useEffect(() => {
        httprequest.get("/events")
        .then(res=>{
            setEvents(res.data.data)
			setLoading(false)
        })
        .catch(err=>{
            console.log(err.response)
        })
    }, [])
    
    return (
       <>
		<section class="head">
			<div class="bg-video">
				<video autoplay muted loop class="bg-video__content">
					<source src="video/ds_intern.mp4" type="video/mp4" />
					<source src="video/ds_intern.webm" type="video/webm" />	
					Your browser does not support the background video!
				</video>
			</div>
			<div class="head__text-box">
                <h1 class="heading-pry">
                    <span class="heading-pry--main">unilag design studio</span>
                    <span class="heading-pry--sub">A home for innovators</span>
                </h1>
			    <a href="#gallery" class="btn btn--white btn--animated">Chech our designs</a>
			</div>
		</section>

		<div class="popup" id="popup-drone">
			<div class="popup__content">
				<div class="popup__left">
					<img src={drone} alt="x1 " class="popup__image" />
					<img src={drone2} alt="x2" class="popup__image" />
				</div>
				<div class="popup__right">
					<a href="#section-tours" class="popup__icon"> &times; </a>
					<h2 class="heading-sec u-margin-bottom-small">Drone Program</h2>
					<h3 class="heading-ter u-margin-bottom-small">About &ndash; Please read about the program and ensure you're qualified before applying</h3>
					<p class="popup__text">	This program teaches about introduction to electronics and basic electrical simulations in engineering, and the applications of these to mechanical engineering. As an intern, you will understand how a drone works, be able to fly one and apply its function to solving real-life problems in areas of technology, healthcare and innovation in general. Be sure you want to dive into thhis course before applying. All the best!</p>
					<a href="#section-booking" class="btn btn--green">Apply now</a>
				</div>
			</div>
		</div>

		<div class="popup" id="popup-rice">
			<div class="popup__content">
				<div class="popup__left">
					<img src={interns} alt="x1 " class="popup__image" />
					<img src={interns2} alt="x2 " class="popup__image" />
				</div>
				<div class="popup__right">
					<a href="#section-tours" class="popup__icon"> &times; </a>
					<h2 class="heading-sec u-margin-bottom-small">Rice internship</h2>
					<h3 class="heading-ter u-margin-bottom-small">About &ndash; Please read about the program and ensure you're qualified before applying</h3>
					<p class="popup__text"> This program welcomes students of the University of Lagos from different fields of study, who have one or two skills that are technological, electrical or design related to help improve the healthcare sector by making a unique or self-destructive device. interns are usually put in groups and would do a weekly exercise based on a projects given to do	</p>
					<a href="#section-booking" class="btn btn--green">Apply now</a>
				</div>
			</div>
		</div>

		<section class="section-tours" id="section-tours">
			<div class="u-center-text u-margin-bottom-big">
				<h2 class="heading-sec"> Upcoming Events</h2>
			</div>
			<div class="container">
				<div class="row">
					{ loading?<>
					<Skeleton className="col-md-6 " width={80} height={700} />
					<Skeleton className="col-md-6 " width={80} height={700}/>
					</>:events.map(event=>(<div class="col-md-6">
						<div class="card u-margin-bottom-medium">
							<div class="card__side card__side--front">
								<div class="card__picture card__picture--1" style={{backgroundImage: `linear-gradient(to right bottom, ${event.themeColor}33, ${event.themeColor}), url(${event.imageUrl ? event.imageUrl:drone})`}}>
										&nbsp;
								</div>
								
								<h4 class="card__heading">
									<span class="card__heading-span card__heading-span--1" style={{backgroundImage:`linear-gradient(to right bottom, ${event.themeColor}88, ${event.themeColor})`}}>{event.title}</span>
								</h4>

								<div class="card__details">
									<ul>
										{event?.requirements?.map(req=><li>{req}</li>)}
									</ul>
								</div>
							</div>

							<div class="card__side card__side--back card__side--back-1" style={{backgroundImage:`linear-gradient(to right bottom, ${event.themeColor}33, ${event.themeColor})`}}>
								<div class="card__cta">
									<div class="card_price-box">
										<p class="card__price-text"> For more info</p>
										<p class="card__price-value">Read about the program</p>
									</div>
									
									<div class="form__group">
										<Link to={`/event/${event?._id}`} class="btn btn--white btn--animated">
											Read Now!
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>))
					}

				</div>
                <div class="u-center-text">
                    <a href="#contact" class="btn-text u-margin-bottom-medium"> Contact UDS</a>
                </div>
            </div>
        </section>

		<Practical />
		<section class="section-features" id="section-features">
			<div class="container">
				<div class="row">
					<div class="col-md-3">
						<div class="feature-box u-margin-bottom-small">
							<i class="feature-box__icon fa fa-globe"></i>
							<h3 class="heading-ter u-margin-bottom-small"> Meet new interns worldwide</h3>
							<p class="feature-box__text">
								This provides an avenue to interact with other students all over the world and share ideas.
							</p>
						</div>
					</div>

					<div class="col-md-3">
						<div class="feature-box u-margin-bottom-small">
							<i class="feature-box__icon fa fa-compass"></i>
							<h3 class="heading-ter u-margin-bottom-small"> Share information</h3>
							<p class="feature-box__text">
								There are so many online platforms to exchange information to better understand ones's work.
							</p>
						</div>
					</div>

					<div class="col-md-3">
						<div class="feature-box u-margin-bottom-small">
							<i class="feature-box__icon fa fa-map"></i>
							<h3 class="heading-ter u-margin-bottom-small"> Create your own work space</h3>
							<p class="feature-box__text">
								You can create your own workspace on linkedn and the likes in order to be easily accessed.
							</p>
						</div>
					</div>

					<div class="col-md-3">
						<div class="feature-box">
							<i class="feature-box__icon fa fa-heart"></i>
							<h3 class="heading-ter u-margin-bottom-small"> Be a better software/hardware programmer</h3>
							<p class="feature-box__text">
								You will have an expanded knowledge on the use of multiple softwares and hardwares.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="section-stories" id="section-stories">
			<div class="bg-video">
				<video autoplay muted loop class="bg-video__content">
					<source src="video/video.mp4" type="video/mp4" />
					<source src="video/video.webm" type="video/webm" />	
					Your browser does not support the background video!
				</video>
			</div>

			<div class="u-center-text u-margin-bottom-big">
				<h2 class="heading-sec">
					Check out our Interns' review
				</h2>
			</div>

			<div class="container">
				<div class="row u-margin-bottom-medium">
					<div class="story">
						<figure class="story__shape">
							<img src={vicky} alt="person on a tour" class="story__image" />
							<figcaption class="story__caption">Victor Otubure</figcaption>
						</figure>

						<div class="story__text">
							<h3 class="heading-ter u-margin-bottom-small">I got more hardware skills</h3>
							<p>
								I have been a software engineer, a graphics designer, and a 3D designer, but unilag design studio helped expand my knowledge to a very tremendous level, and now I have the feeling of completeness as an engineer. So, I am really grateful I was one of the first set of interns
							</p>
						</div>
					</div>
				</div>

				<div class="row u-margin-bottom-medium">
					<div class="story">
						<figure class="story__shape">
							<img src={ayboss} alt="person on a tour" class="story__image" />
							<figcaption class="story__caption">Lawal Ayobami</figcaption>
						</figure>

						<div class="story__text">
							<h3 class="heading-ter u-margin-bottom-small">Wow! My life is completely different now</h3>
							<p>
								I am a software engineer with alot of other skills, but I have always had a thing for harware, like the arduino kit. Alot has changed in my life as a result of my participation in the first internship program held at the design studio and sponsored by Rice University.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="u-center-text ">
				<Link to="/intern-review" class="btn-text"> 
					Read all stories &rarr;
				</Link>
			</div>
		</section>

		<section class="section-booking" id="section-booking">
			<div class="container">
				<div class="row">
					<div class="book">
						<div class="book__form">
							<form action="#" class="form">
								<div class="u-margin-bottom-aftersmall">
									<h2 class="heading-sec">
										Enrol for program
									</h2>
								</div>

								<div class="form__group">
									<input type="text" placeholder="Full Name" id="name" class="form__input" required />
									<label for="name" class="form__label"> Full Name</label>
								</div>

								<div class="form__group">
									<input type="email" placeholder="Email Address" id="email" class="form__input" required />
									<label for="email" class="form__label"> Email Address</label>
								</div>

								<div class="form__group">
									<div class="form__radio-group">
										<input type="radio" class="form__radio-input" id="small" name="rad" />
										<label for="small" class="form__radio-label">
											<span class="form__radio-button"></span>
											Female
										</label>
									</div>

									<div class="form__radio-group u-margin-bottom-small">
										<input type="radio" class="form__radio-input" id="large" name="rad" />
										<label for="large" class="form__radio-label">
											<span class="form__radio-button"></span>
											Male
										</label>
									</div>
								</div>

								<div class="form__group">
									<button onclick="sweetalertUnav()" type="button" 
									class="btn btn--green btn--animated">Next step &rarr;
									</button>									
								
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="gallery" id="gallery">
			<div class="image__holder-1">
                <img src={clothing} alt="age-1" class="gallery__image image-1" />
            </div>
            <div class="image__holder-2">
                <img src={pic2} alt="age-2" class="gallery__image image-2" />
            </div>
            <div class="image__holder-3">
                <img src={pics3} alt="age-3" class="gallery__image image-3" />
            </div>
            <div class="image__holder-4">
                <img src={pics4} alt="age-4" class="gallery__image image-4" />
            </div>
            <div class="image__holder-5">
                <img src={pics5} alt="age-5" class="gallery__image image-5" />
            </div>
            <div class="image__holder-6">
                <img src={pics6} alt="age-6" class="gallery__image image-6" />
            </div>
            <div class="image__holder-7">
                <img src={pics7} alt="age-7" class="gallery__image image-7" />
            </div>
            <div class="image__holder-8">
                <img src={pics8} alt="age-8" class="gallery__image image-8" />
            </div>
            <div class="image__holder-9">
                <img src={pics9} alt="age-9" class="gallery__image image-9" />
            </div>
            <div class="image__holder-10">
                <img src={pics10} alt="age-10" class="gallery__image image-10" />
            </div>
            <div class="image__holder-11">
                <img src={pics11} alt="age-11" class="gallery__image image-11" />
            </div>
            <div class="image__holder-12">
                <img src={pics12} alt="age-12" class="gallery__image image-12" />
            </div>
            <div class="image__holder-13">
                <img src={pics13} alt="age-13" class="gallery__image image-13" />
            </div>
            <div class="image__holder-14">
                <img src={pics14} alt="age-14" class="gallery__image image-14" />
            </div>
        </section>
		</>
    )
}

export default Home
