import React,{useEffect} from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'

function AdminContainer() {
    const navigate = useNavigate()
    useEffect(()=>{
        const token = localStorage.getItem("dstoken")
        if(!token){
            return navigate("/")
        }
    },[])
    return (
        <div className="admin">
            <Sidebar />
            <Outlet />
        </div>
    )
}

export default AdminContainer
