import React from 'react'
import arduino from "../assets/arduino1.jpg";
import arduino2 from "../assets/arduino2.jpg";
import matterform from "../assets/matterandform1.jpg";
import matterform2 from "../assets/matterandform2.jpg";
import ultimaker from "../assets/ultimaker1.jpg"
import ultimaker2 from "../assets/ultimaker2.jpg"

function Practical() {
    return (
        <section class="section-about" id="section-about">
        <div class="u-center-text u-margin-bottom-big">
            <h2 class="heading-sec">
                Practical work at the studio
            </h2>
        </div>
        
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h3 class="heading-ter u-margin-bottom-small"> Arduino sessions are simply enjoyable</h3>
                    <p class="paragraph">
                        Inters are taught electrical circuitry in order to have a feel of how embedded systems work and apply it in solving real life problems.
                    </p>
                    <h3 class="heading-ter u-margin-bottom-small"> 3D scanning can be really handy</h3>
                    <p class="paragraph">
                        Our students learn to use the MATTER AND FORM 3D SCANNER so as to easily 3D print some complicated designs.
                    </p>

                    <a href="#" class="btn-text u-margin-bottom-medium">
                        See more &rarr;
                    </a>
                </div>
                <div class="col-md-6">
                    <div class="composition">
                        <img srcset={`${arduino} 300w, ${arduino2} 1000w" `}
                        sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 44vw, 286px"
                        alt="arduino" 
                        class="composition__photo composition__photo--p1" />

                        <img srcset={`${matterform} 300w, ${matterform2} 1000w `} 
                        sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 44vw, 286px"
                        alt="arduino 2" 
                        class="composition__photo composition__photo--p2" />

                        <img srcset={`${ultimaker} 300w, ${ultimaker2} 1000w`} 
                        sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 44vw, 286px"
                        alt="arduino 3" 
                        class="composition__photo composition__photo--p3" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    )
}

export default Practical
