import { Skeleton } from '@mui/material'
import React, {useState,useEffect} from 'react'
import parse from "html-react-parser"
import { Link } from 'react-router-dom'
import Sidebar from '../../components/Sidebar'
import httprequest from '../../httpsrequests/httprequest'
import "../../styles/admin.css"

function Dashboard() {
    const [events, setEvents]=  useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        httprequest.get("/events")
        .then(res=>{
            setEvents(res.data.data)
            setLoading(false)
        })
        .catch(err=>{
            console.log(err.response)
        })
    }, [])
    
    const getCertainAmount = (text)=>{
        return text.slice(0,100)
    }

    return (
       
            <div className="admin__main">
                <h1 className="main__heading">All Available Events</h1>
                {
                    loading? 
                    <>
                        <Skeleton height={"100px"}  />
                        <Skeleton height={"100px"}  />
                        <Skeleton height={"100px"}  />
                    </>
                    :<ul className="events__list">
                        {
                            events.map(event=><li key={event._id} className="events__item">
                                <Link to={`/admin/events/${event._id}`} className="events__item--link">
                                    <h2 className="events__heading">{event.title}</h2>
                                    <p className="events__text">{event?.description && parse(getCertainAmount(event?.description))}</p>
                                </Link>
                            </li>)
                        }
                    </ul>
                }
            </div>
        
    )
}

export default Dashboard
