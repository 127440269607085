import React from 'react';
import {Outlet} from "react-router-dom"
import Footer from '../../components/Footer';
import Header from '../../components/Header';

function HomeContainer() {
    return (
        <>
            <main>
                <Header />
                    <Outlet />
                </main>
            <Footer />
        </>
    )
}

export default HomeContainer
