import React, {useState, useEffect} from 'react'
import {useNavigate,Link, useParams} from "react-router-dom"
import AddIcon from '@mui/icons-material/Add';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
// import EventsAddForm from './EventsAddForm';
import httprequest from '../../httpsrequests/httprequest';

function EventEdit() {
    const navigate = useNavigate()
    const {id} = useParams();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("")
    const [requirements, setRequirements] = useState([""]);
    const [benefits, setBenefits] = useState([""]);
    const [deadline, setDeadline] = useState("")
    const [formlink, setGoogleLink] = useState("")
    console.log(description)
    useEffect(() => {
        httprequest.get(`/events/${id}`)
        .then(res=>{
            const data = res.data.data
            setTitle(data.title);
            setDescription(data.description);
            setRequirements(data.requirements);
            setBenefits(data.benefits);
            setDeadline(data.deadline);
            setGoogleLink(data.formlink)
        })
        .catch(err=>{
            console.log(err.response)
        })
    }, [])
    

    const handleDescription = (e)=>{
        setDescription(e)
    }
    const addRequirements = ()=>{
        setRequirements([...requirements,""])
    }
    const addBenefits = () =>{
        setBenefits([...benefits, ""])
    }
    const handleRequirement = (e,index)=>{
        const {value} = e.target;
        setRequirements(requirements.map((req,ind)=>{
            if(index === ind ){
                return value
            }
            return req
        }))
    }
    const handleBenefits = (e,index)=>{
        const {value} = e.target;
        setBenefits(benefits.map((ben,ind)=>{
            if(index === ind ){
                return value
            }
            return ben
        }))
    }
    const handleSubmit = async(e)=>{
        e.preventDefault();
        try{
            const response = await httprequest.patch(`/events/${id}`,{title,description,benefits,deadline,requirements,formlink})
            //redirect to page with new event
            console.log(response);
            navigate("/admin")
        }catch(err){

        }
    }
    return (
      
            <div className="admin__main">
                <h1 className="main__heading">Edit Event</h1>
                <form className="event__form">
                    <div className="event__form-group">
                        <label className="event__form-label">Event Title / Name </label>
                        <input type="text" value={title} onChange={e=>setTitle(e.target.value)} className="event__form-input" />
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label">description </label>
                        <ReactQuill style={{height: "200px"}} value={description} onChange={handleDescription} />
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label">Requirements for participation</label>
                        {requirements.map((req,index)=><input type="text" onChange={(e)=>handleRequirement(e,index)} className="event__form-input" value={req} />)}
                        <button onClick={addRequirements} type="button" className="event__form-icon"><AddIcon /></button>
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label">Benefits for participation</label>
                        {benefits.map((ben,index)=><input type="text" onChange={(e)=>handleBenefits(e,index)} className="event__form-input" value={ben} />)}
                        <button onClick={addBenefits} type="button" className="event__form-icon"><AddIcon /></button>
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label">set deadline for submission</label>
                        <input type="date" onChange={e=>setDeadline(e.target.value)}/>
                    </div>
                    <div className="event__form-group">
                        <label className="event__form-label">Google Form Link</label>
                        <input type="text" value={formlink} onChange={e=>setGoogleLink(e.target.value)} className="event__form-input" />
                    </div>
                    <button onClick={handleSubmit} href="#section-booking" class="btn btn--green">
                            Submit
                        </button>
                    {/* <EventsAddForm /> */}
                </form>
            </div>
       
    )
}

export default EventEdit
