import React from 'react'

function Footer() {
    return (
        <footer class="footer">
		<ul class="nav">
			<div class="col-md-2">
				<li class="nav__item"><a href="#section-features" class="nav__link">benefits of UDS</a></li>
			</div>
			<div class="col-md-2">
				<li class="nav__item"><a href="#section-stories" class="nav__link">intern review</a></li>
			</div>
			<div class="col-md-2">
				<li class="nav__item"><a href="#contact" class="nav__link">contact us</a></li>
			</div>
			<div class="col-md-2">
				<li class="nav__item"><a href="#section-booking" class="nav__link">enrol now</a></li>
			</div>
			<div class="col-md-2">
				<li class="nav__item"><a href="#section-about" class="nav__link">what we do</a></li>
			</div>
			<div class="col-md-2">
				<li class="nav__item"><a href="#" class="nav__link">join our co op</a></li>
			</div>
		</ul>

		<div class="contact" id="contact">
			<p class="contact-no">
				Tel: 0905 589 1717 
			</p>

			<p class="contact-mail">
				Email: designstudio.engr@unilag.edu.ng
			</p>
		</div>
		
		<p class="copyright">
			Designed by talented_vicky and ay_boss pioneer interns of Rice 360 internship program held in the design studio at the Faculty of Engineering of the University of Lagos.
		</p>
	</footer>

    )
}

export default Footer
